<template>
  <div>
    <header>
      <div
        class="page-header min-vh-70"
        :style="{ 'background-image': 'url(' + data.hero.image_section + ')' }"
      >
        <span class="mask bg-dark opacity-3"></span>
        <div class="container">
          <div class="row position-relative justify-content-center">
            <div class="col-lg-12 mx-auto text-white text-center">
              <h2 class="text-white">{{ data.hero.title_section }}</h2>
              <p class="lead">{{ data.hero.description_section }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div
          class="row bg-white shadow mt-n5 border-radius-lg pb-4 p-3 mx-sm-0 mx-1 position-relative justify-content-center"
        >
          <div class="col-lg-4 mt-lg-n2 mt-2">
            <label class="">Tanggal Nginap</label>
            <div class="input-group">
              <!-- <span class="input-group-text"><i class="fas fa-calendar"></i></span> -->
              <input
                class="form-control datepicker"
                placeholder="Please select date"
                type="date"
                v-model="form_cek_kamar.start_date"
              />
            </div>
          </div>
          <div class="col-lg-4 mt-lg-n2 mt-2">
            <label class="">Kategori Lama Menginap</label>
            <select
              class="form-control"
              name="choices-leave"
              id="choices-leave"
              placeholder="Kategori Lama Menginap"
              v-model="form_cek_kamar.jenis_menginap"
            >
              <option value="harian">Harian</option>
              <option value="mingguan">Mingguan</option>
              <option value="bulanan" selected>Bulanan</option>
            </select>
          </div>
          <div class="col-lg-4 mt-lg-n2 mt-2">
            <label class="" v-if="form_cek_kamar.jenis_menginap == 'harian'"
              >Menginap berapa hari</label
            >
            <label class="" v-if="form_cek_kamar.jenis_menginap == 'mingguan'"
              >Menginap berapa minggu</label
            >
            <label class="" v-if="form_cek_kamar.jenis_menginap == 'bulanan'"
              >Menginap berapa bulan</label
            >
            <div class="form-group">
              <input
                class="form-control"
                :placeholder="`Lama menginap`"
                type="number"
                v-model="form_cek_kamar.kuantitas_menginap"
              />
            </div>
          </div>
          <div class="col-lg-3 mt-lg-n2 mt-2">
            <router-link
              :to="{
                name: 'available-kamar',
                params: {
                  start_date: form_cek_kamar.start_date,
                  jenis_menginap: form_cek_kamar.jenis_menginap,
                  kuantitas_menginap: form_cek_kamar.kuantitas_menginap,
                },
              }"
            >
              <label class="">&nbsp;</label>
              <button type="button" class="btn bg-gradient-primary w-100 mb-0">
                Cek Kamar
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <div class="row position-relative justify-content-center pt-5">
        <div class="col-lg-8 my-5">
          <div
            class="row shadow mt-n5 border border-secondary border-radius-lg pb-4 p-3 mx-sm-0 mx-1 justify-content-center"
          >
            <div class="col-5 mt-2">
              <label>Penghuni Terdaftar</label>
              <div class="count_penghuni">
                {{ data.count.penghuni_terdaftar }}
              </div>
            </div>
            <div class="col-2 mt-2">
              <center>
                <div class="garis_verikal"></div>
              </center>
            </div>
            <div class="col-5 mt-2">
              <label>Sedang Menginap</label>
              <div class="count_penghuni">
                {{ data.count.penghuni_sedang_menginap }}
              </div>
            </div>
          </div>
        </div>
      </div>

    <!-- begin promo -->
    <section v-if="data.promo.length > 0">
      <div class="position-relative">
        <div class="container pb-lg-6 pb-4 pt-6 postion-relative z-index-2">
          <div class="row">
            <div class="col-md-8 mx-auto text-center">
              <h3>Promo</h3>
              <p>Lebih Murah Dengan Ikut Promo Sekarang!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-1">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-3 mb-lg-auto mb-4 my-auto p-md-0 ms-auto me-auto"
              v-for="(data_list_promo, index_list_promo) in data.promo"
              :key="index_list_promo"
            >
              <div class="card min-height-800 max-height-800">
                <img
                  class="card-img-top max-height-200 min-height-200"
                  :src="data_list_promo.image_promo"
                />
                <div
                  class="card-header text-center pt-1.5 pb-3 min-height-150 max-height-150"
                >
                  <h6 class="text-dark opacity-8 mb-0">
                    {{ data_list_promo.title_promo }}
                  </h6>
                  <p class="py-1" style="font-size: 0.8rem">
                    <span v-html="data_list_promo.description_promo"></span>
                  </p>
                </div>
                <div class="card-body mx-auto pt-0 max-height-100 min-height-100">
                  <div
                    class="justify-content-start d-flex px-2 py-1"
                    v-for="(
                      data_benefit_promo, index_benefit_promo
                    ) in data_list_promo.benefit_promo"
                    :key="index_benefit_promo"
                  >
                    <div>
                      <i class="fas fa-check text-dark opacity-6 text-sm"></i>
                    </div>
                    <div class="ps-2">
                      <span class="text-sm">{{ data_benefit_promo }}</span>
                    </div>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-center mt-6">
                  <a :href="data_list_promo.action.wa">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalMessage"
                    >
                      Hubungi Kami
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end promo -->

    <!-- begin fasilitas -->
    <section class="pt-5 pb-0">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-8">
            <section class="py-4">
              <div class="container text-center">
                <h2>Fasilitas</h2>
                <p>
                  SISTEM LISTRIK TOKEN PRABAYAR PERKAMAR ( DITANGGUNG OLEH
                  PENGHUNI/KOSTUMER KAMAR MINGGUAN DAN BULANAN ) ” Bangunan bagus serta
                  nyaman dengan fasilitas hotel bintang 3, Beserta Rooftop dengan
                  pemandangan kota yang indah, Berdekatan dengan fasilitas umum ( atm ,
                  masjid , minimarket , warung makan , swalayan , cafe , pom bensin dll ).
                  10 – 15 menit ke daerah kampus.<br />

                  Harga Sudah Termasuk Untuk 2 Orang Penginap.
                </p>
              </div>
            </section>
          </div>
        </div>
        <div class="row">
          <div class="col-12 ms-auto">
            <section class="py-4">
              <div class="container">
                <div class="row justify-space-between py-2">
                  <div class="col-lg-2 col-md-4 my-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="icon h-50 card-fasilitas text-primary">
                          <i class="fas fa-video"></i>
                        </div>
                        <h5>CCTV</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 my-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="icon h-50 card-fasilitas text-primary">
                          <i class="fas fa-tv"></i>
                        </div>
                        <h5>TV LED</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 my-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="icon h-50 card-fasilitas text-primary">
                          <i class="fas fa-wifi"></i>
                        </div>
                        <h5>FREE WIFI</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 my-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="icon h-50 card-fasilitas text-primary">
                          <i class="fas fa-car-battery"></i>
                        </div>
                        <h5>GENSET</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 my-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="icon h-50 card-fasilitas text-primary">
                          <i class="fas fa-tint"></i>
                        </div>
                        <h5>PAM</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 my-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="icon h-50 card-fasilitas text-primary">
                          <i class="fas fa-wind"></i>
                        </div>
                        <h5>AC</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section class="">
          <div class="container">
            <div class="row mt-5">
              <div
                :class="[data_list_facility_image.class_facility]"
                v-for="(data_list_facility_image, index_list_facility_image) in data
                  .facility.list_facility_image"
                :key="index_list_facility_image"
              >
                <a href="javascript:;">
                  <div class="card card-background mb-4">
                    <div
                      class="full-background"
                      :style="{
                        'background-image':
                          'url(' + data_list_facility_image.image_facility + ')',
                      }"
                    ></div>
                    <div class="card-body pt-12">
                      <h4 class="text-white text-decoration-underline-hover">
                        {{ data_list_facility_image.title_facility }}
                      </h4>
                      <p>{{ data_list_facility_image.description_facility }}</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <!-- end fasilitas -->

    <!-- begin aturan -->
    <section class="">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ms-auto me-auto text-center">
            <h3 class="mb-0 mt-4">{{ data.snk.title_section }}</h3>
            <p>{{ data.snk.description_section }}</p>
          </div>
        </div>
        <div class="row mt-5 d-flex justify-content-center">
          <div class="col-md-4">
            <img
              src="@/assets/10492.jpg"
              class="img-fluid"
              alt="Responsive image"
              style="height: 500px; width: 100%"
            />
          </div>

          <div class="col-md-6 align-self-center">
            <div class="info-horizontal d-flex">
              <div class="text-dark">
                <ol>
                  <li
                    v-for="(data_list_snk, index_list_snk) in data.snk.list_snk"
                    :key="index_list_snk"
                  >
                    {{ data_list_snk }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end aturan -->

    <!-- begin kamar -->
    <section class="pt-5 pb-0">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-8">
            <section class="py-4">
              <div class="container">
                <h2>{{ data.room_type.title_section }}</h2>
                <p>
                  {{ data.room_type.description_section }}
                </p>
              </div>
            </section>
          </div>
        </div>
        <div class="row">
          <div
            class="col-sm-6 ms-auto"
            v-for="(data_list_room_type, index_list_room_type) in data.room_type
              .list_room_type"
            :key="index_list_room_type"
          >
            <section class="py-4">
              <div class="container">
                <a href="javascript:;">
                  <div class="card card-background mt-lg-0 mt-5">
                    <div
                      class="full-background"
                      :style="{
                        'background-image':
                          'url(' + data_list_room_type.image_type_room[0] + ')',
                      }"
                    ></div>
                    <div class="card-body pt-7 text-center">
                      <h2 class="text-white max-width-300 mb-3">
                        {{ data_list_room_type.title_type_room }}
                      </h2>
                      <p class="lead text-sm">
                        {{ data_list_room_type.description_type_room }}
                      </p>
                      <router-link
                        :to="{ name: '', params: { id: data_list_room_type.type_id } }"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-white bg-transparent mt-3"
                        >
                          Dapatkan
                        </button>
                      </router-link>
                    </div>
                  </div>
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <!-- end kamar -->

    <!-- begin tertimonial -->
    <section class="py-5">
      <slide :data="data.testimonial" />
    </section>
    <!-- end tertimonial -->

    <!-- begin galery -->
    <section class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-8 mx-auto text-center mb-5">
            <h2>{{ data.galleries.title_section }}</h2>
            <p>
              {{ data.galleries.description_section }}
            </p>
          </div>
        </div>
        <div class="row min-vh-25">
          <div
            class="col-sm-4 mb-sm-0 mb-3 mt-4"
            :class="[data_list_galleries.class_col]"
            v-for="(data_list_galleries, index_list_galleries) in data.galleries
              .list_galleries"
            :key="index_list_galleries"
          >
            <div class="card card-background">
              <div
                class="full-background w-100 h-100 bg-cover gambarGalery"
                :style="{ 'background-image': 'url(' + data_list_galleries.image + ')' }"
              ></div>
              <div class="card-body pt-12">
                <h4 class="text-white text-decoration-underline-hover">
                  {{ data_list_galleries.title }}
                </h4>
                <p>{{ data_list_galleries.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style>
.garis_verikal {
  border-left: 1px black solid;
  height: 50px;
  width: 0px;
}
.count_penghuni {
  font-weight: bold;
  font-size: 2em;
}
</style>

<script>
import Vue from "vue";
import slide from "@/components/slide.vue";

export default {
  name: "homepage",
  components: {
    slide,
  },
  data() {
    return {
      data: null,
      form_cek_kamar: {
        start_date: "",
        jenis_menginap: "bulanan",
        kuantitas_menginap: "",
      },
    };
  },
  created() {},
  mounted() {
    this.get_data_homepage();
    this.load_js_css_template();

    let items = document.querySelectorAll(".carousel .carousel-item");

    items.forEach((el) => {
      const minPerSlide = 4;
      let next = el.nextElementSibling;
      for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0];
        }
        let cloneChild = next.cloneNode(true);
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });
  },
  computed: {},
  methods: {
    load_js_css_template() {
      flatpickr(".datepicker", {
        // mode: "range"
        dateFormat: "d-m-Y",
      }); // flatpickr

      if (document.getElementById("choices-leave")) {
        var element = document.getElementById("choices-leave");
        const example = new Choices(element, {
          searchEnabled: false,
        });
      }
      if (document.getElementById("choices-to")) {
        var element = document.getElementById("choices-to");
        const example = new Choices(element, {
          searchEnabled: false,
        });
      }
    },

    async get_data_homepage() {
      let url_helper = helperUmum.urlPublic();
      await axios
        .get(`${url_helper}/api/home`, {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          this.data = response.data;
        });
    },
  },
};
</script>
