<template>
  <div>
    <div class="footer pt-5 bg-primary">
      <hr class="horizontal dark mb-5" />
      <div class="container">
        <div class="row">
          <div class="col-md-2 mb-4 ms-auto d-flex justify-content-center">
            <div class="logo-footer">
              <img src="@/assets/KOSTEL-WALLPAPER2.png" class="" alt="Responsive image" />
            </div>
          </div>
          <div class="col-md-4 mb-4 ms-auto">
            <div class="">
              <h6 class="font-weight-bolder text-white">KOSTEL BANJARMASIN</h6>
              <div
                class="text-white opacity-8"
                v-for="(data_contact, index_contact) in data.footer.contact"
                :key="index_contact"
              >
                <div class="row">
                  <div class="col-md-12 ms-auto">
                    <i class="text-lg opacity-8" :class="[data_contact.icon_class]"></i>
                  </div>
                  <div class="col-md-12 ms-auto">
                    {{ data_contact.value }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mt-3 mb-2 text-white">Sosial Media</h6>
                <div
                style="display: inline"
                  class="nav-item"
                  v-for="(data_social_media, index_social_media) in data.footer
                    .social_media"
                  :key="index_social_media"
                >
                  <a
                    class="mx-2 pe-1 text-white opacity-8"
                    :href="data_social_media.link"
                    target="_blank"
                  >
                    <i
                      class="fab fa-facebook text-lg opacity-8"
                      :class="[data_social_media.icon_class]"
                    ></i>
                  </a>
                </div>
            </div>
          </div>
          <div class="col-md-3 mb-4">
            <div>
              <h6 class="font-weight-bolder text-sm text-white">Kostel</h6>
              <ul class="flex-column nav">
                <li class="nav-item">
                  <router-link
                    class="nav-link text-white opacity-8"
                    to="/kamar"
                  >
                    Kamar & Fasilitas
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link text-white opacity-8"
                    to="/kenapa-kostel"
                  >
                    Kenapa KOSTEL
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link text-white opacity-8"
                    to="/faq"
                  >
                    FAQ
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link text-white opacity-8"
                    to="/tentang-kami"
                  >
                    Tentang Kami
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link text-white opacity-8"
                    to="/syarat-ketentuan"
                  >
                    Syarat & Ketentuan
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link text-white opacity-8"
                    to="/galeri"
                  >
                    Galery
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link text-white opacity-8"
                    to="/cara-pemesanan"
                  >
                    Cara Pemesanan
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 mb-4 me-auto">
            <div>
              <h6 class="font-weight-bolder text-sm text-white">Lokasi</h6>
              <ul class="flex-column ms-n3 nav">
                <li class="nav-item">
                  <a
                    class="nav-link text-white opacity-8"
                    :href="data.footer.link_google_maps"
                    target="_blank"
                  >
                    {{ data.footer.address }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12">
            <div class="text-center text-white opacity-8">
              <p class="my-4 text-sm">
                Copyright ©
                {{ new Date().getFullYear() }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footer-component",
  data() {
    return {
      data: {
        copyright: document.write(new Date().getFullYear()),
        footer: {
          name_company: "Kostel",
          address:
            "Jalan Sultan Adam, Komplek Taekwondo No.2 ( Ruko Kost Warna Biru Sebelah Kanan )",
          img:
            "https://eefindonesia.id/wp-content/uploads/2020/12/KOSTEL-WALLPAPER-PICTURE-300x300.jpg",
          link_google_maps:
            "https://www.google.com/maps/search/Jalur+III+No.2+Sungai+Andai,+Surgi+Mufti+Kec.+Banjarmasin+Utara+Kota+Banjarmasin,+Kalimantan+Selatan+70122/@-3.301174,114.602933,16z?hl=en",
          social_media: [
            {
              type: "facebook",
              link: "",
              icon_class: "fab fa-facebook",
            },
            {
              type: "instagram",
              link: "https://www.instagram.com/kostel2banjarmasin/",
              icon_class: "fab fa-instagram",
            },
            {
              type: "twitter",
              link: "",
              icon_class: "fab fa-twitter",
            },
          ],
          contact: [
            {
              type: "email",
              value: "eefwisatamandiri@gmail.com",
              icon_class: "fab fa-instagram",
            },
            {
              type: "phone",
              value: "081348513330",
              icon_class: "fas fa-mobile-alt",
            },
          ],
        },
      },
    };
  },
  mounted() {
    document.write(new Date().getFullYear());
  },
};
</script>
