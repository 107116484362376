import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/section/homepage.vue'

const kamar = () => import(/* webpackChunkName: "kamar" */ '../views/section/kamar.vue')
const available = () => import(/* webpackChunkName: "kamar" */ '../views/section/available-kamar-page.vue')
const galeri = () => import(/* webpackChunkName: "galeri" */ '../views/section/galeri.vue')
const singin = () => import(/* webpackChunkName: "singin-page" */ '../views/section/singin-page.vue')
const singup = () => import(/* webpackChunkName: "singup-page" */ '../views/section/singup-page.vue')
const syarat = () => import(/* webpackChunkName: "sk" */ '../views/section/syarat-ketentuan-page.vue')
const cara = () => import(/* webpackChunkName: "carapemesanan" */ '../views/section/cara-pemesanan.vue')
const tentangkami = () => import(/* webpackChunkName: "tentangKami" */ '../views/section/tentang-kami.vue')
const dashboardprofile = () => import(/* webpackChunkName: "profil-dashboard" */ '../views/section/profile-dashboard-page.vue')
const notFound = () => import(/* webpackChunkName: "notFound" */ '../views/section/page-not-found.vue')
const kenapa_kostel = () => import(/* webpackChunkName: "tentangKami" */ '../views/section/kenapa-kostel.vue')
const faq = () => import(/* webpackChunkName: "tentangKami" */ '../views/section/faq.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/signin',
    name: 'signin',
    component: singin
  },
  {
    path: '/signup',
    name: 'signup',
    component: singup
  },
  {
    path: '/syarat-ketentuan',
    name: 'syarat-ketentuan',
    component: syarat
  },
  {
    path: '/cara-pemesanan',
    name: 'cara-pemesanan',
    component: cara
  },
  {
    path: '/tentang-kami',
    name: 'tentang-kami',
    component: tentangkami
  },
  {
    path: '/galeri',
    name: 'galeri',
    component: galeri
  },
  {
    path: '/kamar',
    name: 'kamar',
    component: kamar
  },
  {
    path: '/kenapa-kostel',
    name: 'kenapa-kostel',
    component: kenapa_kostel
  },
  {
    path: '/faq',
    name: 'faq',
    component: faq
  },
  {
    path: '/available-kamar/:start_date/:jenis_menginap/:kuantitas_menginap',
    name: 'available-kamar',
    component: available
  },
  {
    path: '/profil-dashboard',
    name: 'profil-dashboard',
    component: dashboardprofile
  },
  { path: "*", component: notFound }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
