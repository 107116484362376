<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h2 class="mb-0">{{ data.title_section }}</h2>
        <p>
          {{ data.description_section }}
        </p>
      </div>
    </div>
    <div class="container text-center my-3">
      <div class="row mx-auto my-auto">
        <div class="col-lg-12">
          <div class="container">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(
                  data_list_testimonial, index_list_testimonial
                ) in data.list_testimonial"
                :key="index_list_testimonial"
                class="slide"
              >
                <div class="col-md-12 my-3 mx-2">
                  <div class="card shadow min-height-200 max-height-200">
                    <div class="card-body">
                      <div class="author">
                        <!-- <img
                          :src="data_list_testimonial.image"
                          alt="..."
                          class="avatar shadow rounded-circle"
                        /> -->
                        <div class="name ps-2">
                          <!-- <span>{{ data_list_testimonial.name }}</span> -->
                          <div class="stats">
                            <small>{{ data_list_testimonial.date }}</small>
                          </div>
                        </div>
                      </div>
                      <p class="mt-4">{{ data_list_testimonial.testimonial }}</p>
                      <div class="rating mt-3" v-for="star in 5" :key="star">
                        <i
                          v-if="star <= data_list_testimonial.rates"
                          class="fas fa-star"
                        ></i>
                        <i v-else class="far fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "swiper-example-free-mode",
  title: "Free mode / No fixed positions",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: Object,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  created() {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    if (mediaQuery.matches) {
      this.swiperOption.slidesPerView = 1
    }
  },
};
</script>
